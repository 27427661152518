.map-container path {
  fill: #d3d3d3; /* Default color */
  stroke: #8c7558; /* Border color */
  pointer-events: all;
}

.map-container path:hover {
  fill: #B0C4DE !important; /* Light steel blue color for hover effect */
  opacity: 0.8;
  cursor: pointer;
}
.state {
  cursor: pointer;
}

.state:hover {
  fill: #B0C4DE; /* Color change on hover */
}

.state:active {
  fill: #264653; /* Color change when pressed */
}

.container-fluid {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Layout for mobile screens */
@media (max-width: 768px) {
  .container-fluid {
    flex-direction: column;
    align-items: center;
  }

  .col-sm-6 {
    order: 1; /* Display map first */
    width: 100%; /* Take full width */
  }

  .col {
    order: 2; /* Display text box second */
    width: 100%; /* Take full width */
    margin-top: 20px; /* Add space between map and text */
  }
}
.tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    pointer-events: none;
    transform: translate(-50%, -100%);
    white-space: nowrap;
    font-size: 12px;
  }


.helpfulText {
  color: #777 !important;
  font-size: 14px !important;
  margin-top: 20px !important;
}

.card-container .not-logged-in {
  color: #c6b0a0
}

/* Container for all reviews */
.detail-reviews-container {
  padding: 20px;
}

/* Grid layout for cards */
.detail-reviews-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual review card */
.detail-reviews-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.2s ease-in-out;
}

.detail-reviews-card:hover {
  transform: translateY(-5px);
}

/* Park name link in the card header */
.detail-reviews-park-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

/* Star rating container */
.detail-reviews-rating {
  margin-bottom: 10px;
}

/* Review text */
.detail-reviews-text {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

/* Actions container */
.detail-reviews-actions {
  display: flex;
  justify-content: space-between;
}

.user-review-pic {
  width: 70px;
  height: 70px;
  margin-top: 10px;
  margin-left: 0px;
}

.user-review-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #c79a61;
  text-decoration: none;
  display: block;
  margin-bottom: 20px;
  margin-top: -3px;
  
}
/* CropModal.css */
.crop-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .crop-container {
    position: relative;
    width: 60%;
    max-width: 400px;
    height: 60%;
    max-height: 400px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* .crop-confirm-button, */
  .crop-cancel-button {

   
   
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .crop-confirm-button {
    background-color: #4CAF50; /* Green */
  }
  
  .crop-cancel-button {
    background-color: #f44336; /* Red */
  }
  
  .crop-confirm-button:hover {
    background-color: #45a049; /* Darker green */
  }
  
  .crop-cancel-button:hover {
    background-color: #e53935; /* Darker red */
  }
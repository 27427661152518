.link-color-light {
  color: #f8f9fa;
}

.link-color-light:hover {
  color: #8c7558;
}


.toggle-container {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Aligns items vertically */
  margin-bottom: 20px; /* Adds some space below the toggle */
}

/* Toggle switch styling remains the same */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bfac95;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #f8f9fa;
  transition: .4s;
}

input:checked + .slider {
  background-color: #0070BB;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-label {
  margin-left: 15px;
  font-size: 1.1em;
  color: #f8f9fa;
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
  }
  
  .review-title {
    width: 100%; /* Make the input take up the full width */
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .review-text {
    width: 100%; /* Make the textarea take up the full width */
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    height: 150px; /* Set a height that suits your design */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .character-count-title2,
  .character-count {
    margin-top: 5px;
    font-size: 12px;
    text-align: right;
  }
  
  .submit-review-btn {
    width: 100%; /* Make the button take up the full width */
    padding: 10px;
    margin-top: 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-review-btn:hover {
    background-color: #0056b3;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .rating-container {
    display: flex;
    gap: 5px; /* Adjust spacing between stars */
  }
  
  .star {
    font-size: 24px; /* Adjust size as needed */
    color: #666; /* Default color */
    cursor: pointer;
    transition: color 0.3s; /* Smooth color transition */
  }
  
  .star-filled {
    color: #c79d69; /* Color for filled stars */
  }
  
  .star:hover {
    color: gold; /* Color on hover */
  }
  

  .readonly-star-rating {
    display: flex;
  }
  
  .readonly-star-rating .star {
    font-size: 24px; /* Adjust size as needed */
    color: #c79d69; /* Color for filled stars */
    cursor: default; /* No pointer cursor */
  }
  
  .readonly-star-rating .star:not(.filled) {
    color: #666; /* Color for unfilled stars */
  }

  @media (max-width: 600px) { /* Adjust the max-width value as needed */
    .readonly-star-rating {
      flex-direction: column; /* Stack stars vertically on small screens */
    }
  }


/* HomePage.css */

.homepage-container {
    background-image: url("./home-image.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .welcome-card-header {
    background-color: #8c7558;
  }
  
  .welcome-card-body {
    background-color: #f2e2c4;
    text-align: center;
  }
  
  .card-title {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .homepage-container {
      background-image: url("./home-image-mobile.webp");
      flex-direction: column;
      padding: 1rem;
      align-items: stretch;
    }
  
    .col-sm-6,
    .col {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }
  
    .col-sm-6 {
      min-height: 300px; /* ✅ Ensure map container has height */
    }
  
    svg {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  
    .card {
      margin-top: 1rem;
      width: 90%;
      max-width: 500px;
    }
  }
  
/* EditReviewForm.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-group input {
    margin-top: 10px;
    font-size: 16px;
}

.form-group textarea {
    height: 150px;
    margin-top: 10px;
    font-size: 16px;
}

.character-count-title,
.character-count-review {
    margin-top: 5px;
    font-size: 12px;
    text-align: right;
    color: #666;
}

.star-rating {
    display: flex;
    gap: 5px;
}

.star {
    font-size: 24px;
    color: #666;
    cursor: pointer;
}

.star.filled {
    color: #c79d69;
}


button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

/* ForgotPassword.css */

.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the start (top) */
    height: 55vh;
    background-color: #000000;
    padding-top: 50px; /* Add some padding at the top */
  }
  
  .forgot-password {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    margin-top: 50px; /* Add margin to the top of the form */
  }
  
  .forgot-password h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .forgot-password-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .forgot-password-button {
    width: 100%;
    padding: 10px;
    background-color: #0070BB;;
    color: #EDDECB;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .forgot-password-button:hover {
    background-color: #073980  ;
    transition: .25s;
    color: #D6B588
  }
  
  .forgot-password-note {
    margin-top: 15px;
    text-align: center;
    color: tan;
  }
  
/* NotificationBar.css */
.notification-bar {
    background-color: #000000;
    color: #bfac95;
    padding: 10px;
    font-size: 14px;
    text-align: center;
    width: 100%;
    top: 0;
    left: 0;
    padding: 12px 10px 5px 10px;
  }
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#detail-page-header {
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  width: 100%;
  background-position: center;
}

#welcome-card-header {
  background-color: #8c7558;
}

#welcome-card-body {
  background-color: #f2e2c4;
}

.logo-img {
  width: 100px;
  height: 100px;
}

.navbar-toggler {
  color: #8c7558;
}

.link {
  color: #6F4E37;
}

#detail-image {
  height: 350px;
  width: auto;
}

.spacer {
  height: 10rem;
}

.small-spacer {
  height: 5rem;
}

.mini-spacer {
  height: 1.75rem;
}

.page-container {
  /* background-image: linear-gradient(to bottom right, #4f6457, #7da3a1); */
  background-color: #000000;
}

/* .custom-nav {
  background-color: #EAE3D2
  ;
} */
/* .custom-nav {
  background-color: #fff;
} */

.footer {
  background-image: url('/public/trees-unsplash.webp'); /* Replace './images/trees-unsplash.webp' with the correct path to your image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px;
}

.footer {
  background-color: #f8f9fa; /* Light background color */
  padding: 20px 0; /* Vertical padding */
  border-top: 1px solid #dee2e6; /* Border at the top */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px; /* Adjust based on your design */
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.logo-section {
  flex: 0 0 auto;
}

.image {
  max-width: 150px; /* Adjust size as needed */
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.list a {
  color: #003153; /* Link color */
  text-decoration: none;
}

.list a:hover {
  text-decoration: underline;
}

.copyright {
  margin-left: auto; /* Pushes it to the far right */
  text-align: right; /* Align text to the right */
}

.footer p {
  margin: 0;
  color: #333; /* Light grey color for text */
}


.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.column {
  flex: 1;
  margin-bottom: 20px;
}

.bottom {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.image {
  width: auto;
  height: auto;
  max-width: 100%;
}

.list {
  list-style: none;
  padding: 0;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #bfac95;
  padding: 0px 25px;
  text-align: center;
}



h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #bfac95;
  padding: 0px 25px;
}

.activities-container {
  display: flex;
  color: #eee7e2
}

.activity-column {
  flex: 1;
  margin: 0 10px;
}

.btn-primary {
  background-color: #0070BB  ; /* Change this to your desired background color */
  border-color: #0070BB; /* Change this to your desired border color */
  color: #FBFCF8; /* Change this to your desired text color */
}

btn-primary focus {
  outline: none; /* Removes the default outline */
  border-color: #0070BB ; /* Optional: Change border color when focused */
  background-color: #0070BB ; /* Optional: Slight background change on focus */
}



/* Hover state for .btn-primary */
.btn-primary:hover {
  background-color: #073980 ; /* Change this to your desired hover background color */
  border-color: #073980 ;
  color: #D6B588;
  transition: .2s;
}

/* Custom CSS for .btn-outline-primary */
.btn-outline-primary {
  color: #0070BB ; /* Change this to your desired text color */
  border-color: #0070BB ;/* Change this to your desired border color */
  background-color: #FBFCF8; /* Ensure the background is transparent */
}

/* Hover state for .btn-outline-primary */
.btn-outline-primary:hover {
  color: #0070BB;; /* Change this to your desired text color on hover */
  background-color: #B0C4DE ;/* Change this to your desired background color on hover */
  border-color: #0070BB ;
  opacity: .7;
  transition: .2s;
}

/* Focus state for .btn-outline-primary */
.btn-outline-primary:focus {
  outline: none; /* Removes default outline */
  border-color: #0070BB; /* Border color when focused */
  background-color: #B0C4DE; /* Background color when focused */
}

/* Active state for .btn-outline-primary */
.btn-outline-primary:active {
  background-color: #B0C4DE; /* Background color when active */
  border-color: #0070BB; /* Border color when active */
  color: #FBFCF8; /* Text color when active */
}



/* .star-rating {
  display: flex;
  gap: 2px;
  color: #c79d69;
} */

.star-rating {
  display: flex;
  gap: 2px;
  color: #555;
  cursor: pointer;
}

.star {
  font-size: 24px; /* Adjust size as needed */
  transition: color 0.3s;
}

.star.filled {
  color: #c79d69;
}

.star:hover {
  color: #666666;
}



.reviews-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure modal is on top of other content */
}

.reviews-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.reviews-modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: black;
}


.card {
  border: 2px solid #8c7558; /* Optional: to clearly define the card boundary */
  display: flex;
  color: #444;
  background-color: #FBFCF8;
  
}

/* #FCFBFC; #F8FBF8 */

.card-body {
  background-color: #FBFCF8; /* The background color you want */
  padding: 16px; /* Adjust as needed */
}

.card-body h5 {
  color: #6F4E37; /* The color you want for the title */
}

/* Common background styles */
.background-image {
  background-image: url('/public/trees-unsplash.webp'); /* Replace with the correct path to your image */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Apply the background image to the nav */
.custom-nav {
  background-color: transparent; /* Remove any solid background color */
  padding: 20px 0; /* Adjust padding as needed */
}

/* Apply the same background image to the footer */
.footer {
  padding: 20px; /* Adjust padding as needed */
}

/* Reuse the background image class */
.custom-nav.background-image,
.footer.background-image {
  background-image: url('/public/trees-unsplash.webp'); /* Replace with the correct path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

footer .a href {
  color: #4C516D
}


.review-card {
  margin: 25px; /* Adds space around each review card */
  padding: 20px; /* Adds space inside each review card */
  border-radius: 12px; /* Optional: Add rounded corners */
  max-width: 700px;
  background-color: #FBFCF8;
  margin-left: 20px;
}

.off-the-wall {
  margin-left: 20px;
  margin-right: 20px;
  color: #E3DFD2;
  margin-top: 35px;

}

.off-the-wall2 {
  margin-left: 5px;
  color: #E3DFD2;
  margin-top: -10px;
  padding: 15px;
  

}

.new-color {
  color: #E3DFD2;
}

.link-dark {
  color: #E3DFD2;
}
.link-dark:hover {
  color: #8c7558;
}

.form-label {
  color: #F2EBDD
  
}


.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.form-container {
  width: 65%;
  margin: 0 auto;
}

/* Ensure the input fields and button are the same width */
.form-container .form-control,
.form-container .btn-primary {
  width: 100%;
}

/* Center text and align with the input areas */
.form-container .form-label,
.form-container p {
  width: 100%;
  text-align: left;
}

/* Optional: Adjust spacing around form elements */
.form-container .mb-3 {
  margin-bottom: 1rem; /* Adjust as needed */
}

.form-container .mini-spacer {
  height: 1.75rem; /* Adjust as needed */
}

.form-container .btn-primary {
  width: 25%;
}

.form-label-link {
  font-size: 14px;
  color: #0070BB;
  text-align: center;
  display: block;
  margin-top: 20px;
}

.form-label-link a {
  color: #0070BB;
  text-decoration: none;
  transition: color 0.3s ease;
}

.form-label-link a:hover {
  color: #073980;
  text-decoration: underline;
  color: #D6B588
}

@media (max-width: 768px) { 
  .form-container .btn-primary {
    width: 33%;
  }
}

.table-sentence {
  font-size: 1rem;
  color: #FBFCF8;
  text-align: center;
  margin-bottom: 20px;
}

.table-cell-center {
  display: flex;
  align-items: center; /* Vertically centers the text */
  justify-content: center; /* Horizontally centers the text */
}

.custom-button {
  width: 90px; /* Set a uniform width */
  /* Center the text inside the button */
}

.form-label-link {
  font-size: 14px;
  color: #007bff;
  text-align: center;
  display: block;
  margin-top: 10px;
}

.form-label-link a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.form-label-link a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.profile-image {
  display: block; /* Make the image a block element */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto; /* Center the image horizontally */
}

.profile-image-container {
  display: flex; /* Set the container to use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  width: 100%; /* Full width to allow centering */
  height: 100%; /* Full height to allow centering */
}

.input-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px; /* space between input and button */
}
